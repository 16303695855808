import { rgba } from 'polished'
import { css } from 'styled-components'

const textLink = css`
  text-decoration: solid underline ${ p => p.theme.colors.red } 1px;
  text-underline-position: under;
`

export const textLinkWithHover = css`
  ${ textLink }
  transition: background-color .3s ease-out;

  &:hover {
    background-color: ${ p => rgba( p.theme.colors.blueGrey__deep, 0.2 ) };
  }
`

export default textLink
