import ThemeProvider from '../styles/ThemeProvider'
import { CookieConsentProvider } from './CookieConsent'
import { AppProvider } from './AppContext'

function ContextProviders( {
  isStatic, hasHeroImage, children, 
} ) {
  if ( isStatic ) {
    return (
      <ThemeProvider>
        <AppProvider 
          hasHeroImage={ hasHeroImage }
        >
          { children }
        </AppProvider>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider>
      <CookieConsentProvider>
        <AppProvider 
          hasHeroImage={ hasHeroImage }
        >
          { children }
        </AppProvider>
      </CookieConsentProvider>
    </ThemeProvider>
  )
}

export default ContextProviders
