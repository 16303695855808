import type { ColorKeys } from '../../styles/colors'
import { getColor } from '../../styles/colors'

function Instagram( { color = 'white' as ColorKeys, title = 'Instagram' } ) {
  const theColor = getColor( color )

  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <title>{ title }</title>
      <path
        d="M10.4972 7.33177C8.56916 7.33177 6.99575 8.98013 6.99575 11C6.99575 13.0199 8.56916 14.6682 10.4972 14.6682C12.4252 14.6682 13.9986 13.0199 13.9986 11C13.9986 8.98013 12.4252 7.33177 10.4972 7.33177ZM20.9988 11C20.9988 9.48097 21.012 7.97571 20.9305 6.45943C20.8491 4.69824 20.4656 3.13519 19.2363 1.84732C18.0044 0.556694 16.515 0.157674 14.8339 0.0723668C13.3839 -0.0129408 11.9471 0.000818524 10.4998 0.000818524C9.04985 0.000818524 7.61303 -0.0129408 6.16571 0.0723668C4.4846 0.157674 2.99262 0.559446 1.76331 1.84732C0.53138 3.13794 0.150505 4.69824 0.0690761 6.45943C-0.0123524 7.97846 0.000781304 9.48373 0.000781304 11C0.000781304 12.5163 -0.0123524 14.0243 0.0690761 15.5406C0.150505 17.3018 0.534007 18.8648 1.76331 20.1527C2.99525 21.4433 4.4846 21.8423 6.16571 21.9276C7.61566 22.0129 9.05248 21.9992 10.4998 21.9992C11.9498 21.9992 13.3866 22.0129 14.8339 21.9276C16.515 21.8423 18.007 21.4406 19.2363 20.1527C20.4682 18.8621 20.8491 17.3018 20.9305 15.5406C21.0146 14.0243 20.9988 12.519 20.9988 11ZM10.4972 16.6441C7.51584 16.6441 5.10976 14.1234 5.10976 11C5.10976 7.87664 7.51584 5.35594 10.4972 5.35594C13.4785 5.35594 15.8846 7.87664 15.8846 11C15.8846 14.1234 13.4785 16.6441 10.4972 16.6441ZM16.1052 6.44292C15.4092 6.44292 14.847 5.85402 14.847 5.12478C14.847 4.39554 15.4092 3.80664 16.1052 3.80664C16.8013 3.80664 17.3634 4.39554 17.3634 5.12478C17.3636 5.29794 17.3312 5.46945 17.2681 5.62947C17.2049 5.78949 17.1123 5.93489 16.9954 6.05733C16.8785 6.17978 16.7397 6.27686 16.587 6.34303C16.4342 6.40919 16.2705 6.44314 16.1052 6.44292Z"
        fill={ theColor }
      />
    </svg>
  )
}

export default Instagram
