export type ColorKeys = 
  'black' 
  | 'white'
  | 'red'
  | 'blueGrey__superlight'
  | 'blueGrey__light'
  | 'blueGrey__mid'
  | 'blueGrey__deep'

const colors = {
  black: '#000000',
  white: '#ffffff',
  red: '#FC5858',
  blueGrey__superlight: '#F6F6F6',
  blueGrey__light: '#ECECEC',
  blueGrey__mid: '#A7C0C0',
  blueGrey__deep: '#879090',
}

export const getColor = ( color: ColorKeys ) => {
  if ( colors[ color ] ) {
    return colors[ color ]
  }

  return color
}

export default colors
