import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'
import Search from './search/Search'
import { CookieConsentBanner } from './CookieConsent'
import MetaTags from './MetaTags'

function AppChrome( { metaTags = null, children } ) {
  return (
    <StyledAppChrome>
      { metaTags && (
        <MetaTags 
          // eslint-disable-next-line react/jsx-props-no-spreading
          { ...metaTags } 
        />
      ) }
      <Header />
      <StyledAppMain>
        { children }
      </StyledAppMain>
      <Footer />
      <Search />
      <CookieConsentBanner />
    </StyledAppChrome>
  )
}

const StyledAppChrome = styled.div`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${ p => p.theme.colors.white };
  cursor: ${ p => p.theme.isLoading ? 'wait' : 'auto' };
`

const StyledAppMain = styled.div`
  z-index: 0;
  flex: 1;
`

export default AppChrome
