import styled from 'styled-components'
import Instagram from './icons/Instagram'
import Twitter from './icons/Twitter'

function SocialLink( { 
  href, 
  orgName,
  instagram = false, 
  twitter = false,
  children = null,
} ) {
  return (
    <StyledSocialLink
      href={ href }
      target="_blank"
    >
      { instagram && <Instagram title={ `Link to the ${ orgName } Instagram account` } /> }
      { twitter && <Twitter title={ `Link to the ${ orgName } Instagram account` } /> }
      { children }
    </StyledSocialLink>
  )
}

const StyledSocialLink = styled.a`
  display: flex;
  color: ${ p => p.theme.fg };
  text-decoration: none;

  svg {
    display: block;
    margin-right: 0.5rem;

    * {
      fill: ${ p => p.theme.fg };
      transition: fill .3s ease-out;
    }
  }

  &:hover {
    svg * {
      fill: ${ p => p.theme.colors.red };
    }
  }
`

export default SocialLink
