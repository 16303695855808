import { useState } from 'react'
import styled from 'styled-components'
import { useAppContext } from './AppContext'
import Link from './Link'
import Container from './layout/Container'
import FULogo, { StyledLogo } from './FULogo'
import Nav, { breakpoint } from './Nav'
import useWindowScroll from '../hooks/useWindowScroll'

function Header() {
  const [isScrolled, setIsScrolled] = useState( false )
  const { hasHeroImage } = useAppContext()
  const color = isScrolled || !hasHeroImage ? 'black' : 'white'

  useWindowScroll( () => {
    if ( window?.scrollY > 50 ) {
      setIsScrolled( true )
    }
    else {
      setIsScrolled( false )
    }
  }, [] )

  return (
    <StyledFixedHeader 
      $withBg={ !hasHeroImage || isScrolled }
    >
      <Container>
        <StyledHeader $condensed={ isScrolled }>
          <Link href="/" passHref>
            <StyledLogo $breakpoint={ breakpoint }>
              <FULogo textColor={ color } />
            </StyledLogo>
          </Link>
          <Nav 
            color={ color } 
            condensedHeader={ isScrolled }
          />
        </StyledHeader>
      </Container>
    </StyledFixedHeader>
  )
}

interface StyledFixedHeader {
  $withBg?: boolean,
}

const StyledFixedHeader = styled.div<StyledFixedHeader>`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: ${ p => p.$withBg ? 0 : '100%' };
    left: 0;
    right: 0;
    background: ${ p => p.theme.colors.white };
    transition: bottom .3s ease-out;
  }

  @media print {
    position: absolute;
  }
`

interface StyledHeader {
  $condensed: boolean,
}

const StyledHeader = styled.div<StyledHeader>`
  display: flex;
  align-items: center;
  padding: ${ p => p.$condensed ? '1.5rem' : '3rem' } 0;
  transition: padding .3s ease-out;

  > * {
    &:first-child {
      margin-right: 2rem;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  ${ StyledLogo } svg > * {
    transition: fill .2s linear;
  }
  
  a > span {
    transition: color .2s linear;
  }
`

export default Header
