import styled from 'styled-components'

function SearchForm( {
  search, onChange, onSubmit, 
} ) {
  return (
    <form
      onSubmit={ onSubmit }
      role="search"
    >
      <StyledSearchForm>
        <input 
          id="search-input"
          type="search" 
          aria-label="Search"
          value={ search }
          onChange={ onChange }
        />
        <button type="submit">
          Search
        </button>
      </StyledSearchForm>
    </form>
          
  )
}

const StyledSearchForm = styled.div`
  display: flex;

  input {
    flex: 1 0 auto;
    border: 1px solid ${ p => p.theme.colors.black };
    ${ p => p.theme.typo.generalSans }
    transition: border-color .3s ease-out;

    &:focus {
      outline: none;
      border-color: ${ p => p.theme.colors.red };
    }
  }

  button {
    flex: 0 0 auto;
    border: 0;
    background: ${ p => p.theme.colors.red };
    ${ p => p.theme.typo.generalSansSemiBold }
  }

  input,
  button {
    padding: 1rem;
    -webkit-appearance: none;
    border-radius: 0;
  }

  @media ( max-width: 500px ) {
    flex-direction: column;
    
    input {
      margin-bottom: 1rem;
    }
  }
`

export default SearchForm
