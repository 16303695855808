import styled, { css } from 'styled-components'
import Container from './layout/Container'
import Image from './Image'
import Link from './Link'
import Chevron from './icons/Chevron'
import extractLinkitParts from '../lib/extractLinkitParts'
import textLink from '../styles/textLink'

const getTitleParts = title => {
  const words = title.split( ' ' )

  if ( words.length < 4 ) {
    return [title, null]
  }

  return [
    words.slice( 0, -2 ).join( ' ' ),
    words.slice( -2 ).join( ' ' ),
  ]
}

const getMaxWidth = copy => {
  if ( !copy ) {
    return null
  }

  if ( copy.length < 60 ) {
    return '40rem'
  }

  if ( copy.length < 45 ) {
    return '35rem'
  }

  return null
}

function Hero( {
  title, 
  intro = null, 
  image = null, 
  link = null, 
  linkStyle = 'link' as 'link' | 'button',
} ) {
  const [mainTitle, lastTwoWords] = getTitleParts( title )
  const titleMaxWidth = getMaxWidth( title )
  const introMaxWidth = getMaxWidth( intro )
  
  const { 
    url: linkUrl, 
    text: linkText, 
    target: linkTarget,
    rel: linkRel,
  } = extractLinkitParts( link )

  const content = (
    <Container>
      <StyledHeroContent 
        $hasImage={ !!image }
        $titleMaxWidth={ titleMaxWidth }
        $introMaxWidth={ introMaxWidth }
      >
        <h1>{ mainTitle } { lastTwoWords && <span>{ lastTwoWords }</span> }</h1>
        { intro && <StyledIntroCopy dangerouslySetInnerHTML={ { __html: intro } } /> }
        { linkUrl && linkText && (
          <StyledLink $style={ linkStyle }>
            <Link 
              href={ linkUrl } 
              target={ linkTarget }
              rel={ linkRel }
            >
              { linkText }
              { linkStyle === 'button' && <span><Chevron /></span> }
            </Link>
          </StyledLink>
        ) }
      </StyledHeroContent>
    </Container>
  )

  return (
    <StyledHero $hasButton={ linkUrl && linkText && linkStyle === 'button' }>
      { image && (
        <>
          <StyledHeroImage>
            <Image
              src={ image.url }
              alt={ image.altText || '' }
              layout="fill"
              objectFit="cover"
              priority
            />
          </StyledHeroImage>
          <StyledHeroContentOverImage>
            { content }
          </StyledHeroContentOverImage>
        </>
      ) }
      { !image && content }
    </StyledHero>
  )
}

const headerPad = '9rem'

interface StyledHero {
  $hasButton: boolean,
}

const StyledHero = styled.div<StyledHero>`
  position: relative;
  margin-bottom: 4rem;

  ${ p => p.$hasButton && css`
    margin-bottom: 0;
  ` }
`

const StyledHeroImage = styled.div`
  position: relative;
  height: 88vh;
  background: #000;

  img {
    opacity: 50%;
  }
`

const StyledHeroContentOverImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  margin-top: -${ headerPad };
`

interface StyledHeroContent {
  $hasImage: boolean,
  $titleMaxWidth: string,
  $introMaxWidth: string,
}

const StyledHeroContent = styled.div<StyledHeroContent>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: ${ headerPad };
  color: ${ p => p.$hasImage ? p.theme.colors.white : p.theme.colors.black };

  > * {
    width: 100%;
  }

  > div {
    max-width: ${ p => p.$introMaxWidth || '52rem' };
  }

  h1 {
    max-width: ${ p => p.$titleMaxWidth || '52rem' };
    margin: 0;
    ${ p => p.theme.typo.generalSansSemiBold }
    font-size: ${ p => p.theme.typo.sizes.tinyHero };

    span {
      @media ( min-width: 600px ) {
        white-space: nowrap;
      }
    }

    @media ( min-width: 500px ) {
      font-size: ${ p => p.theme.typo.sizes.smallHero };
    }

    @media ( min-width: 750px ) {
      font-size: ${ p => p.theme.typo.sizes.hero };
    }
  }
`

const StyledIntroCopy = styled.div`
  ${ p => p.theme.typo.generalSans }
  font-size: ${ p => p.theme.typo.sizes.tinyHero };

  > * {
    &:first-child {
      margin-top: 0;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ( min-width: 750px ) {
    font-size: ${ p => p.theme.typo.sizes.smallHero };
  }
  
  @media ( min-width: 750px ) {
    font-size: ${ p => p.theme.typo.sizes.hero };
  }
`

interface StyledLink {
  $style: 'link' | 'button',
}

const StyledLink = styled.div<StyledLink>`
  ${ p => p.$style === 'link' && css`
    a {
      display: inline-block;
      margin-top: 2rem;
      ${ p.theme.typo.generalSansSemiBold }
      font-size: ${ p.theme.typo.sizes.small };
      color: inherit;
      ${ textLink }
    }
  ` }

  ${ p => p.$style === 'button' && css`
    a {
      justify-self: flex-end;
      display: inline-flex;
      align-items: center;
      margin-top: 4rem;
      padding-left: 1rem;
      ${ p.theme.typo.generalSansSemiBold }
      background: ${ p.theme.colors.black };
      color: ${ p.theme.colors.white };
      text-decoration: none;
      
      > span {
        margin-left: 1rem;
        padding: 0.8rem;
        background: ${ p.theme.colors.red };

        svg {
          height: 22px;

          > * {
            stroke: ${ p.theme.colors.black };
          }
        }
      }
    }
  ` }
`

export default Hero
