const { NEXT_PUBLIC_APP_HOSTNAME } = process.env

export const request = async (
  query,
  variables = {},
  options: {
    schemaToken?: string,
    previewToken?: string,
    preview?: string,
  } = null,
) => {
  let endpoint = '/graphql'

  if ( !NEXT_PUBLIC_APP_HOSTNAME ) {
    throw new Error( 'NEXT_PUBLIC_APP_HOSTNAME must be provided.' )
  }

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  } as any

  if ( typeof window === 'undefined' ) {
    // process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
    endpoint = 'http://caddy/graphql'
    headers.Host = `${ NEXT_PUBLIC_APP_HOSTNAME }`
  }

  if ( options?.previewToken ) {
    endpoint += `?token=${ options.previewToken }`
  }

  if ( options?.schemaToken ) {
    headers.Authorization = `Bearer ${ options.schemaToken }`
  }

  const body = { query } as any

  if ( variables ) {
    body.variables = variables
  }

  return fetch( endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify( body ),
  } )
    .then( r => r.json() )
    .then( r => {
      if ( r.errors ) {
        console.log( r )
      }

      return r
    } )
    .catch( e => {
      console.error( e )
      throw e
    } )
}
