import Head from 'next/head'
import Custom404 from './404'
import ContextProviders from '../components/ContextProviders'
import { GAProvider } from '../lib/ga'
import '../styles/global.css'

const gaTrackingId = 'G-847ZYPQWZB'

function App( { Component, pageProps } ) {
  return (
    <GAProvider gaTrackingId={ gaTrackingId }>
      <ContextProviders
        isStatic={ !!pageProps.static }
        hasHeroImage={ !!pageProps.entry?.heroSection?.[ 0 ]?.heroImage?.[ 0 ]?.url }
      >
        <Head>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#000000" />
          <meta name="theme-color" content="#ffffff" />
        </Head>
        { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
        { pageProps.status !== 404 && <Component { ...pageProps } /> }
        { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
        { pageProps.status === 404 && <Custom404 { ...pageProps } /> }
      </ContextProviders>
    </GAProvider>
  )
}

export default App
