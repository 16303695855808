export const formatPostDate = dateTime => {
  const d = new Date( dateTime )
  const now = new Date()
  const year = d.getFullYear() === now.getFullYear() ? null : d.getFullYear()

  const month = (
    d.getFullYear() === now.getFullYear()
    && d.getMonth() === now.getMonth()
    && d.getDate() === now.getDate()
  ) 
    ? null 
    : d.toLocaleString( 'en-GB', { month: 'short' } )

  const day = (
    d.getFullYear() === now.getFullYear()
    && d.getMonth() === now.getMonth()
    && d.getDate() === now.getDate()
  ) 
    ? 'Today' 
    : d.getDate()

  return [day, month, year].filter( i => !!i ).join( ' ' )
}
