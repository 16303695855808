import { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { request } from '../../craft/graphql'
import useRouteChange from '../../hooks/useRouteChange'
import useModalTraits from '../../hooks/useModalTraits'
import { useAppContext } from '../AppContext'
import Container from '../layout/Container'
import Stack from '../layout/Stack'
import SearchForm from './SearchForm'
import SearchResults from './SearchResults'

const SEARCH_QUERY = `
  query Search(
    $search: String!
  ) {
    entries(
      search: $search
    ) {
      id
      title
      sectionHandle
      slug
      postDate
    }
  }
`

function Search() {
  const searchModalRef = useRef()
  const { searchIsOpen, toggleSearch } = useAppContext()
  const [search, setSearch] = useState( '' )
  const [results, setResults] = useState( null )

  useRouteChange( 'routeChangeComplete', () => {
    toggleSearch( false )
  } )

  useModalTraits( {
    isOpen: searchIsOpen,
    close: () => toggleSearch( false ),
    containerRef: searchModalRef,
    focusOnOpen: '#search-input',
  } )

  const doSearch = e => {
    e.preventDefault()

    request( SEARCH_QUERY, { search } )
      .then( r => setResults( r?.data?.entries ) )
  }

  return (
    <StyledSearch 
      $isOpen={ searchIsOpen }
      ref={ searchModalRef }
    >
      <Container>
        <Stack
          top={ 6 }
          between={ 3 }
          bottom={ 6 }
        >
          <StyledSearchHeader>
            <h1>Search</h1>
            <button
              type="button"
              onClick={ () => toggleSearch( false ) }
            >
              Close
            </button>
          </StyledSearchHeader>
          <SearchForm
            search={ search }
            onChange={ e => setSearch( e.target.value ) }
            onSubmit={ doSearch }
          />
          <SearchResults
            results={ results }
          />
        </Stack>
      </Container>
    </StyledSearch>
  )
}

interface WithIsOpen {
  $isOpen: boolean,
}

const StyledSearch = styled.div<WithIsOpen>`
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: ${ p => p.theme.colors.white };
  pointer-events: none;
  opacity: 0;

  ${ p => p.$isOpen && css`
    pointer-events: all;
    opacity: 1;
  ` }
`

const StyledSearchHeader = styled.div`
  display: flex;

  h1 {
    margin: 0 2rem 0 0;
    ${ p => p.theme.typo.generalSansSemiBold };
  }

  button {
    position: relative;
    margin-left: auto;
    text-indent: -999rem;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 0;

    &:after {
      content: '✕';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-indent: 0;
    }
  }
`

export default Search
