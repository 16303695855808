const extractLinkitParts = linkitMarkup => {
  if ( !linkitMarkup ) {
    return { 
      url: null, 
      text: null, 
      target: null, 
      rel: null, 
    }
  }

  const pattern = /<a.*href="(.+?)".*>(.+?)<\/a>/
  const result = linkitMarkup.match( pattern )
  const targetPattern = /<a.*target="(.+?)".*>.+<\/a>/
  const targetResult = linkitMarkup.match( targetPattern )
  const relPattern = /<a.*rel="(.+?)".*>.+<\/a>/
  const relResult = linkitMarkup.match( relPattern )
  const url = result?.[ 1 ]
  const text = result?.[ 2 ]
  const target = targetResult?.[ 1 ]
  const rel = relResult?.[ 1 ]

  return {
    url, text, target, rel, 
  }
}

export default extractLinkitParts
