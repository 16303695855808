import { useEffect } from 'react'

const usePreventBodyScroll = preventScroll => {
  useEffect( () => {
    if ( preventScroll ) {
      document.body.style.overflowY = 'hidden'
    }
    else {
      document.body.style.overflowY = 'unset'
    }
    
    return () => {
      document.body.style.overflowY = 'unset'
    }
  }, [preventScroll] )
}

export default usePreventBodyScroll
