import styled from 'styled-components'
import { formatPostDate } from '../../lib/date'
import Link from '../Link'

function SearchResults( { results } ) {
  return (
    <StyledResults>
      { results?.length === 0 && (
        <p>There are no results for your search.</p>
      ) }
      { results?.length > 0 && (
        <>
          <p>{ results.length } result{ results.length !== 1 && 's' }</p>
          <ol>
            { results.map( result => (
              <ResultItem key={ result.id } result={ result } />
            ) )}
          </ol>
        </>
      ) }
    </StyledResults>
  )
}

function ResultItem( { result } ) {
  const { url, label, date } = getResultInfo( result )

  if ( !url ) {
    return null
  }

  return (
    <StyledResult>
      <Link href={ url }>
        { result.title }
        <div>
          { label && <span>{ label }</span> }
          { date && <span>{ date }</span> }
        </div>
      </Link>
    </StyledResult>
  )
}

const getResultInfo = result => {
  if ( result.sectionHandle === 'investigations' ) {
    return {
      url: `/investigations/${ result.slug }`,
      label: 'Investigations',
    }
  }
  
  if ( result.sectionHandle === 'stories' ) {
    return {
      url: `/stories/${ result.slug }`,
      label: 'Stories',
      date: formatPostDate( result.postDate ),
    }
  }
  
  if ( result.sectionHandle === 'courses' ) {
    return {
      url: `/training/${ result.slug }`,
      label: 'Courses',
    }
  }
  
  if ( result.sectionHandle === 'people' ) {
    return {
      url: `/people/${ result.slug }`,
      label: 'People',
    }
  }

  return {
    url: `/${ result.slug }`,
    label: 'Info',
  }
}

const StyledResults = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;

  > * {
    max-width: 40rem;
  }

  > p {
    margin: 0;
    padding: 0 0 2rem 0;
    ${ p => p.theme.typo.generalSansSemiBold };
    font-size: ${ p => p.theme.typo.dropped };
    color: ${ p => p.theme.colors.blueGrey__deep };
  }

  ol {
    margin: 0;
    padding: 0 0 0 1.5rem;
  }
`

const StyledResult = styled.li`
  padding-bottom: 1rem;
  ${ p => p.theme.typo.generalSansSemiBold }
  font-size: ${ p => p.theme.typo.sizes.normal };

  a {
    color: ${ p => p.theme.colors.black };
    text-decoration: none;
  }

  span {
    ${ p => p.theme.typo.generalSans }
    font-size: ${ p => p.theme.typo.sizes.normal };

    &:nth-child( 2 ) {
      display: inline-block;
      color: ${ p => p.theme.colors.blueGrey__deep };
      border-left: 1px solid ${ p => p.theme.colors.blueGrey__deep };
      padding-left: 0.5rem;
      margin-left: 0.5rem;
    }
  }
`

export default SearchResults
