import styled from 'styled-components'
import { rgba } from 'polished'
import Link from './Link'
import Container from './layout/Container'
import SocialLink from './SocialLink'
import FULogo from './FULogo'

function Footer() {
  return (
    <StyledFooter>
      <Container>
        <StyledFooterGrid>
          <StyledFooterLinks>
            <Link href="/training">
              <span>Training</span>
            </Link>
            <Link href="/helpdesk">
              <span>Helpdesk</span>
            </Link>
            <Link href="/investigations">
              <span>Stories & Investigations</span>
            </Link>
            <Link href="/about">
              <span>About</span>
            </Link>
            <Link href="/privacy">
              <span>Privacy policy</span>
            </Link>
          </StyledFooterLinks>
          <StyledFollowUs>
            Follow us
            <SocialLink
              orgName="Finance Uncovered"
              href="https://twitter.com/finuncovered"
              twitter
            />
          </StyledFollowUs>
          <div>
            <StyledLogo>
              <Link href="/">
                <FULogo textColor="white" />
              </Link>
            </StyledLogo>
            <StyledCopyright>
              © { new Date().getFullYear() } Finance Uncovered
            </StyledCopyright>
          </div>
          <StyledAddress>
            Third Floor<br />
            The Pill Box<br />
            115 Coventry Road<br />
            London E2 6GG
          </StyledAddress>
        </StyledFooterGrid>
      </Container>
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  padding-top: min( max( 5vw, 1rem ), 4rem );
  border-top: 1px solid ${ p => p.theme.bg };
  background: ${ p => p.theme.colors.black };
  color: ${ p => p.theme.colors.white };

  @media print {
    border-top: 1px solid ${ p => p.theme.colors.black };
    background: transparent;
    color: ${ p => p.theme.colors.black };
  }
`

const StyledFooterGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > * {
    padding-bottom: min( max( 5vw, 1rem ), 4rem );

    &:nth-child( even ) {
      flex: 0 1 160px;
    }
    
    &:nth-child( odd ) {
      flex: 1 0 1fr;
      min-width: 60%;
      margin-right: auto;
    }
  }

  @media ( max-width: 900px ) {
    display: block;
  }
`

const StyledFooterLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem -1rem;
  
  a {
    padding: 0.5rem 1rem;
    ${ p => p.theme.typo.generalSansSemiBold }
    font-size: ${ p => p.theme.typo.sizes.dropped };
    color: inherit;
    text-decoration: none;
    
    span {
      border-bottom: 1px solid transparent;
      transition: border-color .3s ease-out;
      white-space: nowrap;
    }

    &:hover {
      span {
        border-color: ${ p => p.theme.colors.white };
      }
    }
  }

  @media ( max-width: 450px ) {
    display: block;
    margin: 0;
    
    a {
      display: block;
      padding: 0.5rem 0;
      border-bottom: 1px solid ${ p => rgba( p.theme.colors.white, 0.3 ) };

      &:hover {
        span {
          border-color: transparent;
        }
      }
    }
  }

  @media print {
    display: none;
  }
`

const StyledFollowUs = styled.div`
  display: flex;
  align-items: center;
  ${ p => p.theme.typo.generalSans }
  font-size: ${ p => p.theme.typo.sizes.dropped };

  a {
    margin-left: 0.8rem;
  }

  svg > * {
    fill: ${ p => p.theme.colors.white };
  }

  @media print {
    a:after {
      content: ' ' attr(href);
    }

    svg > * {
      fill: ${ p => p.theme.colors.black };
    }
  }
`

const StyledLogo = styled.div`
  @media print {
    display: none;
  }
`

const StyledCopyright = styled.div`
  margin-top: 1rem;
  ${ p => p.theme.typo.generalSans }
  font-size: ${ p => p.theme.typo.sizes.dropped };

  @media print {
    margin-top: 0;
  }
`

const StyledAddress = styled.div`
  ${ p => p.theme.typo.generalSans }
  font-size: ${ p => p.theme.typo.sizes.dropped };
`

export default Footer
