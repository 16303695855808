import { useEffect } from 'react'
import { useRouter } from 'next/router'

const useRouteChange = ( eventName, fn ) => {
  const { events } = useRouter()

  useEffect( () => {
    events.on( eventName, fn )
    
    return () => {
      events.off( eventName, fn ) 
    }
  }, [fn, events] )
}

export default useRouteChange
