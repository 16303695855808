import NextLink from 'next/link'

function Link( {
  children, 
  href, 
  target = null, 
  rel = null, 
  ...props
} ) {
  if ( target === '_blank' || isExternalURL( href ) ) {
    return (
      <a 
        href={ href }
        target={ target }
        rel={ rel }
      >
        { children }
      </a>
    )
  }

  return (
    <NextLink
    // eslint-disable-next-line react/jsx-props-no-spreading
      { ...props }
      href={ href }
      // scroll={ false }
    >
      <a>
        { children }
      </a>
    </NextLink>
  )
}

const isExternalURL = url => {
  if ( !( /^(?:[a-z]+:)?\/\//i ).test( url ) ) {
    return false
  }

  return new URL( url ).origin !== `https://${ process.env.NEXT_PUBLIC_APP_HOSTNAME }`
}

export default Link
