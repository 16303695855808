import {
  useState, createContext, useContext, useMemo,
} from 'react'

const AppContext = createContext( null )
AppContext.displayName = 'AppContext'

export const useAppContext = () => useContext( AppContext )

export function AppProvider( { hasHeroImage, children } ) {
  const [searchIsOpen, setSearchIsOpen] = useState( false )

  const toggleSearch = ( always: boolean = null ) => setSearchIsOpen( 
    s => always !== null ? always : !s, 
  )

  const value = useMemo( () => ( {
    hasHeroImage,
    searchIsOpen,
    toggleSearch,
  } ), [
    hasHeroImage, 
    searchIsOpen,
  ] )

  return (
    <AppContext.Provider 
      value={ value }
    >
      { children }
    </AppContext.Provider>
  )
}
