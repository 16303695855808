import Head from 'next/head'
import { imageUrl } from './Image'

function MetaTags( {
  type = 'website' as 'article' | 'profile' | 'website',
  url,
  title,
  description = null,
  image = null,
  twitterCard = null,
} ) {
  const twitterCardType = twitterCard || image ? 'summary_large_image' : 'summary'

  const optimizedImage = image 
    ? imageUrl( {
      src: image,
      width: 1200,
      height: 627,
      fit: 'cover',
    } )
    : null

  return (
    <Head>
      <title>{ title }</title>
      <meta property="og:type" content={ type } />
      <meta property="og:url" content={ url } />
      <meta property="og:title" content={ title } />
      { description && <meta property="og:description" content={ description } /> }
      { optimizedImage && <meta property="og:image" content={ optimizedImage } /> }
      <meta name="twitter:card" content={ twitterCardType } />
    </Head>
  )
}

export default MetaTags
