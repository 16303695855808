import styled from 'styled-components'
import type { ColorKeys } from '../styles/colors'
import { getColor } from '../styles/colors'

interface StyledLogo {
  $breakpoint: number,
}

export const StyledLogo = styled.span<StyledLogo>`
  @media ( max-width: ${ p => p.$breakpoint }px ) {
    svg {
      max-width: 116px;
    }
  }
`

function FULogo( { textColor = 'black' as ColorKeys, lineColor = 'red' as ColorKeys } ) {
  const textColorValue = getColor( textColor )
  const lineColorValue = getColor( lineColor )

  return (
    <svg 
      width="138" 
      height="52" 
      viewBox="0 0 138 52" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <title>Link to the Finance Uncovered website homepage</title>
      <path d="M14.2313 2.26945L14.4161 4.9875C14.469 5.54167 14.8915 5.96389 15.4989 5.96389C16.1064 5.96389 16.6081 5.51528 16.5817 4.88194L16.3705 1.24028C16.3441 0.633333 15.8423 0.263889 15.2877 0.263889H2.02998C1.44897 0.263889 1 0.7125 1 1.26667C1 1.82083 1.44897 2.26945 2.02998 2.26945H4.30122V16.7569H2.02998C1.44897 16.7569 1 17.1792 1 17.7333C1 18.3139 1.44897 18.7361 2.02998 18.7361H9.63598C10.217 18.7361 10.666 18.3139 10.666 17.7333C10.666 17.1792 10.217 16.7569 9.63598 16.7569H6.46681V10.6611H10.0849V12.35C10.0849 12.9306 10.5603 13.4056 11.1677 13.4056C11.7752 13.4056 12.277 12.9306 12.277 12.35V7.09861C12.277 6.51806 11.7752 6.04306 11.1677 6.04306C10.5603 6.04306 10.0849 6.51806 10.0849 7.09861V8.65556H6.46681V2.26945H14.2313Z" fill={ textColorValue } />
      <path d="M24.1139 7.23056C24.1139 6.65 23.6649 6.22778 23.1103 6.22778H19.0432C18.4886 6.22778 18.0396 6.62361 18.0396 7.20417C18.0396 7.73195 18.4886 8.18056 19.0432 8.18056H22.0011V16.7833H18.8848C18.3301 16.7833 17.9076 17.2056 17.9076 17.7597C17.9076 18.3139 18.3301 18.7361 18.8848 18.7361H27.2302C27.7848 18.7361 28.2074 18.3139 28.2074 17.7597C28.2074 17.2056 27.7848 16.7833 27.2302 16.7833H24.1139V7.23056ZM20.6806 2.08472C20.6806 3.21945 21.6314 4.16944 22.767 4.16944C23.9026 4.16944 24.8533 3.21945 24.8533 2.08472C24.8533 0.95 23.9026 0 22.767 0C21.6314 0 20.6806 0.95 20.6806 2.08472Z" fill={ textColorValue } />
      <path d="M30.2118 16.7833C29.6572 16.7833 29.2083 17.2056 29.2083 17.7597C29.2083 18.3139 29.7101 18.7361 30.2118 18.7361H35.9163C36.4709 18.7361 36.9199 18.3139 36.9199 17.7597C36.9199 17.2319 36.4974 16.7833 35.9163 16.7833H34.0413V12.8514C34.0413 9.57917 36.1012 7.86389 38.1612 7.86389C39.376 7.86389 40.7757 8.52361 40.7757 10.4764V16.7833H39.376C38.8214 16.7833 38.3724 17.2056 38.3724 17.7597C38.3724 18.3139 38.8742 18.7361 39.376 18.7361H44.6051C45.1862 18.7361 45.6351 18.3139 45.6351 17.7597C45.6351 17.2319 45.1069 16.7833 44.6051 16.7833H42.8885V10.5292C42.8885 7.46806 40.9606 5.96389 38.3196 5.96389C36.6558 5.96389 34.8864 6.75556 33.9884 8.39167V7.23056C33.9884 6.65 33.5395 6.22778 32.9585 6.22778H30.1854C29.6044 6.22778 29.1555 6.62361 29.1555 7.20417C29.1555 7.73195 29.6836 8.18056 30.1854 8.18056H31.9285V16.7833H30.2118Z" fill={ textColorValue } />
      <path d="M55.5153 17.7597C55.5153 18.3139 55.9642 18.7361 56.5452 18.7361H58.9221C59.6616 18.7361 60.0049 18.2347 60.0049 17.7333C60.0049 17.1792 59.5031 16.7833 58.9749 16.7833H57.5752V10.3972C57.5752 7.54722 55.6737 5.96389 52.663 5.96389C50.9728 5.96389 49.1505 6.43889 48.2526 6.96667C47.698 7.28333 47.4339 7.62639 47.4339 8.075C47.4339 8.57639 47.7772 9.025 48.4374 9.025C48.728 9.025 48.8864 8.91945 49.4674 8.62917C50.1013 8.28611 51.1841 7.89028 52.531 7.89028C55.0135 7.89028 55.436 9.31528 55.436 10.45V11.1889L52.9271 11.1361C50.1277 11.1361 46.8265 11.9014 46.8265 15.2C46.8265 17.7069 48.86 19 51.3689 19C52.8479 19 54.4325 18.525 55.5153 17.2847V17.7597ZM55.436 15.2792C54.8814 16.3875 53.112 17.2056 51.501 17.2056C50.1013 17.2056 48.9656 16.5194 48.9656 15.1736C48.9656 12.9833 51.8971 12.9306 53.9043 12.9306C54.406 12.9306 54.9342 12.9306 55.436 12.9569V15.2792Z" fill={ textColorValue } />
      <path d="M61.986 16.7833C61.4314 16.7833 60.9825 17.2056 60.9825 17.7597C60.9825 18.3139 61.4843 18.7361 61.986 18.7361H67.6906C68.2452 18.7361 68.6941 18.3139 68.6941 17.7597C68.6941 17.2319 68.2716 16.7833 67.6906 16.7833H65.8155V12.8514C65.8155 9.57917 67.8754 7.86389 69.9354 7.86389C71.1502 7.86389 72.5499 8.52361 72.5499 10.4764V16.7833H71.1502C70.5956 16.7833 70.1467 17.2056 70.1467 17.7597C70.1467 18.3139 70.6484 18.7361 71.1502 18.7361H76.3793C76.9604 18.7361 77.4093 18.3139 77.4093 17.7597C77.4093 17.2319 76.8811 16.7833 76.3793 16.7833H74.6627V10.5292C74.6627 7.46806 72.7348 5.96389 70.0938 5.96389C68.43 5.96389 66.6606 6.75556 65.7626 8.39167V7.23056C65.7626 6.65 65.3137 6.22778 64.7327 6.22778H61.9596C61.3786 6.22778 60.9297 6.62361 60.9297 7.20417C60.9297 7.73195 61.4579 8.18056 61.9596 8.18056H63.7027V16.7833H61.986Z" fill={ textColorValue } />
      <path d="M84.9175 5.96389C81.5107 5.96389 78.5264 8.39167 78.5264 12.5083C78.5264 16.2292 80.9297 19 84.7591 19C86.3701 19 88.2451 18.5514 89.5392 17.0208C89.8033 16.7042 90.2523 16.1236 90.2523 15.5958C90.2523 14.9889 89.7769 14.6194 89.2751 14.6194C88.9054 14.6194 88.6413 14.7514 88.4036 15.0944C87.7434 16.0444 86.819 17.0736 84.9704 17.0736C82.1709 17.0736 80.7448 14.9625 80.7448 12.4556C80.7448 9.5 82.8048 7.78472 84.9439 7.78472C85.6834 7.78472 86.3965 8.02222 86.7398 8.25972C86.2908 8.55 86.0267 9.07778 86.0267 9.63194C86.0267 10.6347 86.8718 11.4 87.9018 11.4C88.9846 11.4 89.8033 10.5819 89.8033 9.39445C89.8033 7.49444 87.6113 5.96389 84.9175 5.96389Z" fill={ textColorValue } />
      <path d="M103.181 17.0208C103.498 16.6778 103.894 16.1236 103.894 15.5958C103.894 14.9889 103.445 14.6194 102.944 14.6194C102.574 14.6194 102.283 14.7778 102.046 15.1208C101.306 16.2028 100.223 17.0472 98.4804 17.0472C95.9714 17.0472 94.4661 14.8833 94.4661 12.6931H102.864C103.709 12.6931 104 12.2444 104 11.5583C104 8.81389 101.914 5.96389 98.3747 5.96389C94.8886 5.96389 92.2477 8.60278 92.2477 12.5347C92.2477 16.15 94.6774 19 98.4276 19C100.118 19 101.94 18.4194 103.181 17.0208ZM98.3219 7.78472C100.144 7.78472 101.465 9.025 101.729 10.8194H94.6774C95.0471 9.10417 96.4468 7.78472 98.3219 7.78472Z" fill={ textColorValue } />
      <path d="M16.9519 25.0309H18.9649C19.5476 25.0309 19.9979 24.5767 19.9979 24.0155C19.9979 23.4543 19.5476 23 18.9649 23H12.502C11.9193 23 11.469 23.4543 11.469 24.0155C11.469 24.5767 11.9193 25.0309 12.502 25.0309H14.8594V34.8115C14.8594 38.2321 12.8728 40.076 10.0122 40.076C7.15157 40.076 5.218 38.2588 5.218 34.865V25.0309H7.4959C8.07862 25.0309 8.52891 24.5767 8.52891 24.0155C8.52891 23.4543 8.07862 23 7.4959 23H1.033C0.450284 23 0 23.4543 0 24.0155C0 24.5767 0.450284 25.0309 1.033 25.0309H3.04604V34.9184C3.04604 40.744 7.284 41.9733 9.95922 41.9733C12.4755 41.9733 16.9519 40.7975 16.9519 34.7848V25.0309Z" fill={ textColorValue } />
      <path d="M21.3413 39.7285C20.7851 39.7285 20.3348 40.1561 20.3348 40.7173C20.3348 41.2785 20.838 41.706 21.3413 41.706H27.0626C27.6188 41.706 28.0691 41.2785 28.0691 40.7173C28.0691 40.1828 27.6453 39.7285 27.0626 39.7285H25.182V35.7468C25.182 32.4332 27.248 30.6962 29.314 30.6962C30.5324 30.6962 31.9362 31.3643 31.9362 33.3418V39.7285H30.5324C29.9762 39.7285 29.5259 40.1561 29.5259 40.7173C29.5259 41.2785 30.0291 41.706 30.5324 41.706H35.7769C36.3596 41.706 36.8099 41.2785 36.8099 40.7173C36.8099 40.1828 36.2801 39.7285 35.7769 39.7285H34.0552V33.3952C34.0552 30.2954 32.1216 28.7722 29.4729 28.7722C27.8042 28.7722 26.0296 29.5738 25.129 31.2307V30.0549C25.129 29.4669 24.6787 29.0394 24.096 29.0394H21.3148C20.7321 29.0394 20.2818 29.4402 20.2818 30.0281C20.2818 30.5626 20.8116 31.0169 21.3148 31.0169H23.063V39.7285H21.3413Z" fill={ textColorValue } />
      <path d="M44.3401 28.7722C40.9233 28.7722 37.9302 31.2307 37.9302 35.3994C37.9302 39.1674 40.3405 41.9733 44.1812 41.9733C45.7969 41.9733 47.6775 41.519 48.9754 39.9691C49.2403 39.6484 49.6906 39.0605 49.6906 38.526C49.6906 37.9114 49.2138 37.5373 48.7105 37.5373C48.3397 37.5373 48.0748 37.6709 47.8365 38.0183C47.1743 38.9803 46.2472 40.0225 44.3931 40.0225C41.5854 40.0225 40.1551 37.8847 40.1551 35.346C40.1551 32.353 42.2211 30.616 44.3666 30.616C45.1083 30.616 45.8234 30.8565 46.1678 31.097C45.7175 31.391 45.4526 31.9255 45.4526 32.4866C45.4526 33.5021 46.3002 34.2771 47.3332 34.2771C48.4192 34.2771 49.2403 33.4487 49.2403 32.2461C49.2403 30.3221 47.0418 28.7722 44.3401 28.7722Z" fill={ textColorValue } />
      <path d="M57.7309 28.7722C54.1021 28.7722 51.6918 31.685 51.6918 35.3994C51.6918 39.0872 54.1021 41.9733 57.7309 41.9733C61.3861 41.9733 63.77 39.0872 63.77 35.3994C63.77 31.685 61.3861 28.7722 57.7309 28.7722ZM57.7309 40.0225C55.2411 40.0225 53.8638 37.8579 53.8638 35.3994C53.8638 32.9142 55.2411 30.7496 57.7309 30.7496C60.2472 30.7496 61.6245 32.9142 61.6245 35.3994C61.6245 37.8579 60.2472 40.0225 57.7309 40.0225Z" fill={ textColorValue } />
      <path d="M72.6536 42C73.1833 42 73.6071 41.7595 73.819 41.2785L78.3748 31.0169H79.7257C80.2819 31.0169 80.7057 30.5626 80.7057 30.0281C80.7057 29.4402 80.2819 29.0394 79.7257 29.0394H74.6401C74.0839 29.0394 73.6071 29.4402 73.6071 30.0281C73.6071 30.5626 74.0839 31.0169 74.6401 31.0169H76.1234L72.7066 39.0338L69.2897 31.0169H70.7995C71.3557 31.0169 71.806 30.5626 71.806 30.0281C71.806 29.4402 71.3557 29.0394 70.7995 29.0394H65.608C65.0517 29.0394 64.6015 29.4402 64.6015 30.0281C64.6015 30.5626 65.0517 31.0169 65.608 31.0169H66.9323L71.4617 41.2785C71.6736 41.7595 72.1238 42 72.6536 42Z" fill={ textColorValue } />
      <path d="M92.4816 39.9691C92.7994 39.6217 93.1968 39.0605 93.1968 38.526C93.1968 37.9114 92.7465 37.5373 92.2432 37.5373C91.8724 37.5373 91.581 37.6976 91.3426 38.045C90.601 39.1406 89.515 39.9958 87.7669 39.9958C85.2506 39.9958 83.7408 37.8045 83.7408 35.5865H92.1637C93.0113 35.5865 93.3027 35.1322 93.3027 34.4374C93.3027 31.6582 91.2102 28.7722 87.6609 28.7722C84.1646 28.7722 81.5159 31.4444 81.5159 35.4262C81.5159 39.0872 83.9527 41.9733 87.7139 41.9733C89.4091 41.9733 91.2367 41.3854 92.4816 39.9691ZM87.6079 30.616C89.4356 30.616 90.7599 31.872 91.0248 33.6892H83.9527C84.3235 31.9522 85.7273 30.616 87.6079 30.616Z" fill={ textColorValue } />
      <path d="M97.7149 31.0169V39.7285H95.8873C95.3311 39.7285 94.8808 40.1561 94.8808 40.7173C94.8808 41.2785 95.384 41.706 95.8873 41.706H102.933C103.489 41.706 103.939 41.2785 103.939 40.7173C103.939 40.1828 103.516 39.7285 102.933 39.7285H99.8604V35.4262C99.8604 32.1392 101.556 30.5626 103.171 30.5626C103.542 30.5626 103.939 30.6428 104.204 30.7764C103.833 31.0169 103.595 31.4444 103.595 31.9522C103.595 33.0211 104.469 33.6357 105.449 33.6357C106.456 33.6357 107.383 32.8875 107.383 31.5781C107.383 29.9212 105.82 28.7722 103.913 28.7722C102.059 28.7722 100.549 29.7342 99.7809 31.0703V30.0549C99.7809 29.4669 99.3306 29.0394 98.7479 29.0394H95.9667C95.384 29.0394 94.9337 29.4402 94.9337 30.0281C94.9337 30.5626 95.4635 31.0169 95.9667 31.0169H97.7149Z" fill={ textColorValue } />
      <path d="M119.874 39.9691C120.192 39.6217 120.589 39.0605 120.589 38.526C120.589 37.9114 120.139 37.5373 119.636 37.5373C119.265 37.5373 118.974 37.6976 118.735 38.045C117.994 39.1406 116.908 39.9958 115.159 39.9958C112.643 39.9958 111.133 37.8045 111.133 35.5865H119.556C120.404 35.5865 120.695 35.1322 120.695 34.4374C120.695 31.6582 118.603 28.7722 115.054 28.7722C111.557 28.7722 108.908 31.4444 108.908 35.4262C108.908 39.0872 111.345 41.9733 115.107 41.9733C116.802 41.9733 118.629 41.3854 119.874 39.9691ZM115.001 30.616C116.828 30.616 118.153 31.872 118.417 33.6892H111.345C111.716 31.9522 113.12 30.616 115.001 30.616Z" fill={ textColorValue } />
      <path d="M132.888 40.7707C132.888 41.3319 133.312 41.706 133.868 41.706H137.02C137.576 41.706 138 41.2785 138 40.7173C138 40.1828 137.576 39.7553 137.02 39.7285H135.033V23.9887C135.033 23.4543 134.583 23 134 23H130.954C130.372 23 129.921 23.4543 129.921 23.9887C129.921 24.5499 130.372 24.9775 130.954 24.9775H132.888V30.8031C132.04 29.5738 130.584 28.7722 128.835 28.7722C125.18 28.7722 122.902 31.7117 122.902 35.3994C122.902 39.0872 125.127 41.9733 128.756 41.9733C130.504 41.9733 132.04 41.2785 132.888 39.8889V40.7707ZM125.127 35.3994C125.127 32.9142 126.505 30.7496 128.994 30.7496C131.484 30.7496 132.888 32.9142 132.888 35.3994C132.888 37.8579 131.484 40.0225 128.994 40.0225C126.505 40.0225 125.127 37.8579 125.127 35.3994Z" fill={ textColorValue } />
      <path d="M138 48.0029H0V51.0029H138V48.0029Z" fill={ lineColorValue } />
    </svg>
  )
}

export default FULogo
