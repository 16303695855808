import type { ColorKeys } from '../../styles/colors'
import { getColor } from '../../styles/colors'

function BurgerMenu( { color = 'red' as ColorKeys } ) {
  const theColor = getColor( color )

  return (
    <svg 
      width="19" 
      height="19" 
      viewBox="0 0 19 19" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#FC5858" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 13.5L18 18.5" stroke={ theColor } strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default BurgerMenu
