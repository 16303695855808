import styled, { css } from 'styled-components'

export const containerWidth = 87 // vw
export const containerPaddingWidth = ( 100 - containerWidth ) / 2
export const defaultMaxWidth = 65 // rem

/**
 * Only works when Container has a maxWidth
 */
export const getMaxElementWidthInRem = ( { 
  maxWidth = defaultMaxWidth,
  cols = 1,
  gutter = 0,
} ) => ( maxWidth - ( gutter * ( cols - 1 ) ) ) / cols

interface Container {
  max?: number | null;
  centered?: boolean,
  fullWidth?: boolean,
}

const Container = styled.div<Container>`
  position: relative;
  display: block;
  width: ${ p => p.fullWidth ? 100 : containerWidth }%;
  margin: 0px auto;

  ${ p => !p.fullWidth && css`
    padding-left: ${ containerPaddingWidth }%;
    padding-right: ${ containerPaddingWidth }%;
  ` }

  ${ p => p.max && css`
    max-width: ${ p.max }rem;

    ${ p.fullWidth && css`
      max-width: calc( ${ p.max }rem + ( ${ containerPaddingWidth }vw * 2 ) );
    ` }
  ` }

  ${ p => p.centered && css`
    text-align: center;
  ` }
`

Container.defaultProps = {
  max: 65,
}

export default Container
