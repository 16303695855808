import type { ColorKeys } from '../../styles/colors'
import { getColor } from '../../styles/colors'

function Twitter( { color = 'white' as ColorKeys, title = 'Twitter' } ) {
  const theColor = getColor( color )

  return (
    <svg 
      width="19" 
      height="16" 
      viewBox="0 0 19 16" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <title>{ title }</title>
      <path d="M19 1.9006C18.3013 2.21303 17.5409 2.43788 16.7577 2.52545C17.5709 2.02471 18.1798 1.23362 18.4703 0.300601C17.7072 0.771108 16.8713 1.10104 15.9996 1.27575C15.6353 0.872024 15.1947 0.5504 14.7052 0.330915C14.2157 0.11143 13.6879 -0.00121132 13.1545 9.82343e-06C10.9968 9.82343e-06 9.26139 1.81303 9.26139 4.03788C9.26139 4.3503 9.29792 4.66273 9.35729 4.96332C6.12631 4.78817 3.24468 3.18817 1.32893 0.738471C0.979856 1.3565 0.79693 2.06019 0.799183 2.77634C0.799183 4.17752 1.48648 5.41302 2.53455 6.13965C1.91691 6.11444 1.31373 5.93846 0.774066 5.62604V5.67575C0.774066 7.63787 2.11213 9.26391 3.89545 9.63787C3.56061 9.72803 3.21616 9.77415 2.87021 9.77515C2.61675 9.77515 2.377 9.74912 2.13496 9.71361C2.62817 11.3136 4.06442 12.4757 5.77467 12.5136C4.43661 13.6 2.76061 14.2391 0.940752 14.2391C0.614229 14.2391 0.312823 14.2272 0 14.1894C1.72623 15.3373 3.77443 16 5.98017 16C13.1408 16 17.0591 9.85089 17.0591 4.51362C17.0591 4.33847 17.0591 4.16332 17.0477 3.98817C17.8058 3.41303 18.4703 2.7006 19 1.9006Z" fill={ theColor } />
    </svg>
  )
}

export default Twitter
