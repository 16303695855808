import { css } from 'styled-components'

export const typo = {
  generalSans: css`
    font-family: 'GeneralSans-Variable', sans-serif;
    font-variation-settings: 'wght' 400;
    line-height: 1.25;
  `,
  generalSansSemiBold: css`
    font-family: 'GeneralSans-Variable', sans-serif;
    font-variation-settings: 'wght' 600;
    line-height: 1.25;
  `,
  rowan: css`
    font-family: 'Rowan-Variable', serif;
    font-variation-settings: 'wght' 400;
    line-height: 1.25;
  `,
  rowanItalic: css`
    font-family: 'Rowan-VariableItalic', serif;
    font-variation-settings: 'wght' 400;
    line-height: 1.25;
  `,
  sizes: {
    small: '0.777rem', // 14px
    dropped: '0.888rem', // 16px
    normal: '1rem', // 18px
    medium: '1.333rem', // 24px
    tinyHero: '1.5rem', // 27px
    smallHero: '1.777rem', // 42px
    hero: '2.333rem', // 42px
  },
}

const fonts = css`
  html,
  body,
  input,
  textarea,
  select,
  button {
    ${ typo.rowan }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: black;
    font-size: 18px;
  }
`

export default fonts
