import styled, { css } from 'styled-components'

interface Stack {
  top?: number | string,
  bottom?: number | string,
  between?:
    number | string
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-evenly'
    | 'space-around',
  height?: string,
}

const betweenFlexValues = [
  'flex-start',
  'flex-end',
  'center',
  'space-between',
  'space-evenly',
  'space-around',
]

const Stack = styled.div<Stack>`
  position: relative;

  ${ p => p.height && css`
    height: ${ p.height };
  ` }

  ${ p => (
    p.between
    && typeof p.between === 'string'
    && betweenFlexValues.includes( p.between )
  ) && css`
    display: flex;
    flex-direction: column;
    jusify-content: ${ p.between };
  ` }

  ${ p => (
    p.between
    && typeof p.between === 'string'
    && !betweenFlexValues.includes( p.between )
  ) && css`
    > * {
      padding-bottom: ${ p.between };

      &:last-child {
        padding-bottom: 0;
      }
    }
  ` }

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${ p => p.between && typeof p.between === 'number' && css`
    > * {
      padding-bottom: ${ p.between }rem;

      &:last-child {
        padding-bottom: 0;
      }
    }
  ` }

  ${ p => p.top && typeof p.top === 'number' && css`
    padding-top: ${ p.top }rem;
  ` }

  ${ p => p.top && typeof p.top === 'string' && css`
    padding-top: ${ p.top };
  ` }

  ${ p => p.bottom && typeof p.bottom === 'number' && css`
    padding-bottom: ${ p.bottom }rem;
  ` }

  ${ p => p.bottom && typeof p.bottom === 'string' && css`
    padding-bottom: ${ p.bottom };
  ` }
`

export default Stack

interface StackItem {
  top?: number,
  bottom?: number,
}

export const StackItem = styled.div<StackItem>`
  ${ p => p.top && css`
    padding-top: ${ p.top }rem;
  ` }

  ${ p => p.bottom && css`
    padding-bottom: ${ p.bottom }rem;
  ` }
`
