import Head from 'next/head'
import AppChrome from '../components/AppChrome'
import Hero from '../components/Hero'

function Custom404() {
  return (
    <AppChrome>
      <Head>
        <title>Page not found — Finance Uncovered</title>
      </Head>
      <Hero
        title="Page not found"
      />
    </AppChrome>
  )
}

export default Custom404
