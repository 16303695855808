import type { ColorKeys } from '../../styles/colors'
import { getColor } from '../../styles/colors'

function BurgerMenu( { color = 'white' as ColorKeys } ) {
  const theColor = getColor( color )

  return (
    <svg
      width="28"
      height="21"
      viewBox="0 0 28 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      focusable="false"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M28 1L0 1L0 0L28 0V1Z" fill={ theColor } />
      <path fillRule="evenodd" clipRule="evenodd" d="M28 11.0001H0L0 10.0001H28V11.0001Z" fill={ theColor } />
      <path fillRule="evenodd" clipRule="evenodd" d="M28 21.0001H0L0 20.0001H28V21.0001Z" fill={ theColor } />
    </svg>

  )
}

export default BurgerMenu
