import styled from 'styled-components'
import { useAppContext } from '../AppContext'

function SearchToggle( { ariaLabel, children } ) {
  const { toggleSearch } = useAppContext()

  return (
    <StyledSearchToggle
      type="button"
      aria-label={ ariaLabel }
      onClick={ toggleSearch }
    >
      { children }
    </StyledSearchToggle>
  )
}

const StyledSearchToggle = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

export default SearchToggle
